.login-page-row {
  height: 100%;
  width: 100%;
  margin: 0;
  .box-left {
    width: 50%;
    background: #6e7686;
    padding: 40px;
  }
  .box-right {
    width: 50%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login-info-title {
    font-size: 48px;
    color: white;
    margin: 60px 0;
  }
  .login-info-content {
    font-size: 36px;
    color: white;
    font-weight: 300;
  }
  .view-content {
    .card {
      .card-header {
        color: white !important;
      }
    }
  }
  .card {
    margin: 0 auto;
    width: 100%;
    background: white;
    border: none;
    box-shadow: 0 0 10px rgba(102, 116, 143, 0.08);
    .card-header {
      padding: 0;
    }
    .input-group {
      flex-direction: column;
    }
    .form-group {
      width: 100%;
      margin-bottom: 2em;
    }
    .card-body {
      padding: 25px 45px;
      border-top: 0.25px solid rgba(151, 151, 151, 0.5);
      box-shadow: 0 4px 4px -2px rgba(102, 116, 143, 0.08);
      .alert {
        margin: 0 -20px 16px -20px;
        font-size: 14px;
      }
      a {
        display: inline-block;
        padding: 0.375rem 0;
        color: rgba(102, 116, 143, 0.6);
      }
      .btn-link {
        margin-left: -13px;
        font-size: 1rem;
        color: rgba(102, 116, 143, 0.6);
      }
    }
    .card-footer {
      background: none;
      border: none;
      font-size:20px;
      a {
        color: rgba(102, 116, 143, 0.6);
      }
    }
    .btns-wrapper-login {
      display: flex;
      .btn-primary {
        margin-left: auto;
        font-size: 18px;
      }
    }
    .form-row {
      margin: 0;
    }
  }
}
.list-group-item.active{
  background-color: #66748f !important;
  border-color: #66748f !important;
}

.input-group > .form-control {
  width: 100%;
}
