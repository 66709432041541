html {
  height: 100%;
  min-width: 900px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  letter-spacing: 0.5px;
  color: #4a4a4a;
}
body * {
  outline: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.marign-right-5 {
  margin-right: 10px;
}

.padding-none {
  padding: 0;
}

.table-row-clicking:hover {
  background: rgba(0, 0, 0, 0.05) !important;
  cursor: pointer;
}

.view-container {
  box-sizing: border-box;
  height: 100%;
  padding: 0;
}
.view-container .view-content {
  padding: 0;
  height: 100%;
  box-shadow: none;
}
.navbar + .view-container {
  height: calc(100% - 64px);
  overflow: auto;
  overflow-x: hidden;
  padding: 15px;
}
.navbar + .view-container .view-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1460px;
}
.view-container .view-content .table-wrapper {
  height: calc(100% - 110px);
  box-shadow: 0 0 10px rgba(102, 116, 143, 0.08);
  background: white;
}
.view-container .view-content .view-wrapper {
  height: calc(100% - 51px);
}
.view-content .card-header {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0;
  color: white;
  padding: 20px 40px;
}
.navbar + .view-container .view-content .card-header {
  color: #7c8189;
}

.modal-title {
  color: #7c8189;
  text-transform: uppercase;
  font-weight: 600;
  white-space: normal;
  word-break: break-word;
}
.form-control:focus {
  box-shadow: none;
  border-color: #8e8d8a;
}

.view-name-header {
  margin-bottom: 15px;
  .btn {
    min-height: 38px;
  }
}
.view-name-header h4 {
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  color: #7c8189;
}

.table .material-icons {
  font-size: 13px;
}

.bg-white {
  background: white;
}

.pull-right {
  float: right;
}
.bg-light {
  background: rgb(251, 252, 255) !important;
}
.table {
  color: #212529;
  border: none;
}
.table thead tr th {
  border: none;
  font-size: 12px;
  color: white;
  padding: 10px;
}
.table tbody td {
  border: none;
  padding: 10px;
  font-size: 13px;
}
.table th,
.table td {
  padding: 0.45rem;
}

.table tbody tr:last-of-type {
  border: none;
}
.form-control {
  border: none;
  border: 0.25px solid rgba(151, 151, 151, 0.2);
  background: white;
  border-radius: 0;
  font-size: 16px;
  padding: 10px;
  min-height: 44px;
  &:focus {
    border: 0.25px solid rgba(151, 151, 151, 0.2);
  }
}
.form-control::placeholder {
  font-size: 13px;
}
.form-control:-ms-input-placeholder {
  font-size: 13px;
}
.card-body {
  background: white;
  padding: 20px 40px;
}
.card-footer {
  border: none;
}
.card-header {
  border-bottom: 0.25px solid rgba(151, 151, 151, 0.5);
}
.alone-modal-post {
  box-shadow: 0px 0px 10px rgba(102, 116, 143, 0.08);
}
.card {
  box-shadow: 0px 0px 10px rgba(102, 116, 143, 0.08);
  border: none;
  border-radius: 0;
  .card-header {
    background: #6e7686;
  }
  .card-body {
    padding: 20px 40px;
    .col-sm-12 {
      padding: 0;
    }
  }
  .card-footer {
    background: white;
    border: 0;
    border-top: 0.25px solid rgba(151, 151, 151, 0.2);
  }
}
.form-control::-ms-input-placeholder {
  font-size: 13px;
}
textarea.form-control {
  height: auto !important;
}
.card-body .form-control {
  height: unset;
}
.login-page .form-control {
  height: unset !important;
}
.btn {
  font-size: 13px;
}

.table-wrapper .table {
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
}
.table-wrapper {
  table thead tr {
    background: #66748f;
    color: white;
  }

  /* Flex Table */
  table {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  table thead,
  table tbody {
    display: block;
  }
  table thead {
    margin-right: 0px;
  }
  table tbody {
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  table tr {
    width: 100%;
    display: flex;
    border-bottom: 0.25px solid rgba(151, 151, 151, 0.5);
  }
  table tr td,
  table tr th {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    word-break: break-word;
    white-space: pre-wrap;
    overflow: hidden;
  }

  table table {
    height: 50%;
  }
}
.btn {
  border-radius: 0 !important;
}
.actions-transparent {
  color: transparent !important;
}
.candidate-td {
  flex-direction: column;
  padding: 0 !important;
  padding-bottom: 5px;
  table {
    width: calc(100% - 80px);
    thead {
      tr {
        background: white;
        th {
          color: black;
          font-weight: 600;
          padding: 5px;
        }
      }
    }
    tbody {
      td {
        padding: 5px;
      }
    }
  }
}
h5 {
  font-size: 16px;
  color: #8e8d8a;
  font-weight: 600;
  text-transform: uppercase;
}
.form-label {
  font-size: 13px;
}
label {
  margin-bottom: 0.3rem;
}
.form-check {
  display: inline-block;
}
.font-13 {
  font-size: 13px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #007bff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tr-main i:hover {
  cursor: pointer;
}
.tr-main:nth-child(even) {
  background: #f4f7f9;
}
.loader {
  position: fixed;
  background: white;
  width: 100%;
  height: calc(100% - 59px);
  top: 59px;
  z-index: 10000;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.schedule-row {
  padding: 5px 0;
}
.react-datetime-picker__wrapper {
  border: 1px solid #e5e6e7 !important;
  color: #495057;
  padding: 6px 12px;
  border-radius: 4px;
}
.react-datetime-picker__wrapper input {
  color: #495057;
}
.job-details-row {
  font-size: 13px;
  white-space: pre-wrap;
  padding-bottom: 5px;
}
.job-details-row .span-label {
  font-weight: 400;
}
.h5-job-details {
  margin-bottom: 20px;
  color: #4a4a4a;
  text-transform: capitalize;
  font-weight: 400;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}
.navbar {
  background: #6e7686 !important;
  padding: 0;
  padding-right: 20px;
  .navbar-collapse {
    height: 63px;
    .navbar-nav {
      height: 100%;
      display: flex;
    }
  }
}
.dropdown-menu {
  left: -3px;
  overflow-y: auto;
  max-height: calc(100vh - 64px);
}
.dropdown-toggle.nav-link {
  color: white;
}
.navbar-dark .navbar-nav .nav-link,
.nav-change-pass {
  color: white;
  font-size: 12px;
  border-right: 0;
  font-weight: 800;
  border-bottom: 2px solid transparent;
  align-items: center;
  display: flex;
  padding: 0 10px;
  &:hover {
    color: white;
  }
}
.navbar-dark .navbar-nav .nav-link.active {
  color: white;
  border-bottom-color: white;
}
.nav-logout {
  padding: 0;
}
.nav-logout i {
  padding: 5px 0 0 0;
  color: white;
}
.nav-logout:hover i {
  color: white;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: white;
  border-bottom-color: white;
}
.navbar-brand {
  padding: 0 10px;
  img {
    width: 100px;
  }
}
.btn-primary {
  background: rgba(127, 158, 141, 0.8) !important;
  border-color: rgba(127, 158, 141, 0.8) !important;
  color: white;
  border-radius: 0;
  font-size: 12px;
  text-transform: uppercase;
}
.btn-primary:hover {
  background: #7f9e8d !important;
  border-color: #7f9e8d !important;
}
.border-primary {
  border-color: #e98074 !important;
}
.btn-danger {
  background: #abb0b5 !important;
  border-color: #abb0b5 !important;
  border-radius: 0;
  text-transform: uppercase;
}
.btn-danger:hover {
  background: #abb0b5 !important;
  border-color: #abb0b5 !important;
}
.filterableCard {
  transition: background-color 0.2s ease, color 0.2s ease;
  padding: 20px 10px;
  &.active {
    font-weight: 600;
    color: black;
  }
}
.filterableCard:hover {
  color: black;
  font-weight: 600;
}
.candidates-filter-cards {
  display: flex;
  width: 100%;
  background: #ebeef4;
  justify-content: center;
}
.btn-light {
  background: rgba(47, 91, 176, 0.7) !important;
  border-color: rgba(47, 91, 176, 0.7) !important;
  color: white;
}
.btn-light:hover {
  background: rgba(47, 91, 176, 0.7) !important;
  border-color: rgba(47, 91, 176, 0.7) !important;
  color: white;
}
.btn-light:focus {
  background: rgba(47, 91, 176, 0.7) !important;
  border-color: rgba(47, 91, 176, 0.7) !important;
  color: white;
}
.btn-light:visited {
  background: rgba(47, 91, 176, 0.7) !important;
  border-color: rgba(47, 91, 176, 0.7) !important;
  color: white;
}
.btn-success {
  background: #8ac5a4 !important;
  border-color: #8ac5a4 !important;
}
.btn-success:hover {
  background: #8ac5a4 !important;
  border-color: #8ac5a4 !important;
}
.modal-content {
  background: white !important;
  border-radius: 0;
}
.modal-body {
  max-height: 400px;
  overflow: auto;
  padding: 20px 40px;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background: #6f9a83 !important;
  border-color: #6f9a83 !important;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  background: #abb0b5;
  border-color: #abb0b5;
}
.btn-group.btn-group-actions {
  .btn-danger {
    background: #f15a5a !important;
    border-color: #f15a5a !important;
    color: white;
    text-transform: none !important;
  }
  .btn-primary {
    background: #8ac5a4 !important;
    border-color: #8ac5a4 !important;
    text-transform: none !important;
  }
  .btn {
    border-radius: 50% !important;
    padding: 5px;
    margin-right: 11px;
    font-size: 10px;
    text-align: center;
  }
  .btn-custom {
    padding: 0;
    font-size: 20px;
  }
}
.btn:focus {
  box-shadow: none !important;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background: #7f9e8d !important;
  border-color: #7f9e8d !important;
  pointer-events: none;
  opacity: 0.6;
}
.navbar-dark .navbar-toggler {
  background: black;
}
.list-group-item.active {
  background: #e98074 !important;
  border-color: #e98074 !important;
}
select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* and then whatever styles you want*/
  height: 30px;
  width: 100px;
  padding: 5px;
}
.nav-username {
  margin-right: 10px;
  color: rgb(244, 90, 42);
  font-size: 12px;
  font-weight: 400;
  min-width: 120px;
}

tbody::-webkit-scrollbar {
  -webkit-appearance: none;
}

tbody::-webkit-scrollbar:vertical {
  width: 11px;
}

tbody::-webkit-scrollbar:horizontal {
  height: 11px;
}

tbody::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

tbody::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.purchase-plans-container {
  display: flex;
  padding-top: 100px;
  justify-content: center;
  width: 100%;
}
.purchase-plan-box {
  display: flex;
  height: 250px;
  flex-direction: column;
  width: 300px;
  box-shadow: 0px 0px 10px rgba(102, 116, 143, 0.08);
  .plan-title {
    background: #66748f;
    color: white;
    padding: 20px;
  }
}
.plan-title {
  padding: 10px 0;
  border-bottom: 1px solid #d8c3a5;
  text-align: center;
  width: 100%;
  color: #656563;
  font-size: 16px;
  font-weight: 500;
}
.plan-content {
  text-align: center;
  padding: 10px;
  &:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.plan-content-wrapper {
  padding-top: 10px;
  font-size: 14px;
}
.required-field {
  color: red;
}
.pagination {
  height: 36px;
  margin: 15px 0 0 0;
  padding: 0;
  display: inline-block;
}
.pager,
.pagination ul {
  margin-left: 0;
  *zoom: 1;
}
.pagination ul {
  padding: 0;
  display: inline-block;
  *display: inline;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.pagination li {
  display: inline;
}
.pagination a {
  float: left;
  padding: 0 12px;
  line-height: 34px;
  color: var(--pagination-number-color);
  text-decoration: none;
  border: none;
  border-left-width: 0;
  font-size: 13px;
}
.pagination li {
  &:nth-child(1) {
    a {
      border: 1px solid var(--default-border-color);
      margin-right: 5px;
    }
  }
  &:nth-child(2) {
    a {
      border: 1px solid var(--default-border-color);
    }
  }
  &:last-child {
    a {
      border: 1px solid var(--default-border-color);
    }
  }
  &:nth-last-child(2) {
    a {
      margin-right: 5px;
      border: 1px solid var(--default-border-color);
    }
  }
}
.pagination li.active {
  background: transparent;
  opacity: 1;
  font-weight: 600;
}
.pagination .active a {
  background: transparent !important;
  color: var(--default-header-color);
}
.pagination a:hover {
  background: transparent !important;
  color: var(--pagination-border-hover);
}
.pagination .active a {
  cursor: default;
}
.pagination .disabled a,
.pagination .disabled a:hover,
.pagination .disabled span {
  background-color: transparent;
  cursor: default;
}
.pagination li:first-child a,
.pagination li:first-child span {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.pagination li:last-child a {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.pagination-centered {
  text-align: center;
}
.pagination-right {
  text-align: right;
}

.font-weight-bold {
  font-weight: 800;
}
.loggedChangePassPage {
  .form {
    box-shadow: 0px 0px 10px rgba(102, 116, 143, 0.08);
  }
}
.Toastify__toast {
  padding: 40px;
  text-align: center;
}
.Toastify__toast--error {
  background: #da3535 !important;
  border: 1px solid #da3535;
  padding: 20px !important;
}
.Toastify__toast--success {
  background: #25c352 !important;
  border: 1px solid #25c352;
  padding: 20px !important;
}

.tooltip {
  opacity: 1;
  .tooltip-inner {
    background: white;
    color: #4a4a4a;
    box-shadow: 0px 0px 8px rgba(110, 118, 134, 0.2);
    opacity: 1;
  }
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: white;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-left-color: white;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-right-color: white;
}

.react-calendar {
  border: none !important;
  box-shadow: 0px 0px 8px rgba(110, 118, 134, 0.2);
}

.terms-body {
  max-height: 400px;
  overflow: auto;
}
.form-control-file {
  border: 1px solid transparent;
  padding: 5px;
  &.is-invalid {
    border-color: #dc3545;
  }
}
.hovering-td {
  &:hover {
    cursor: pointer;
    color: black;
    font-weight: 600;
  }
}
.jumbotron {
  height: 100%;
  margin: 0;
}
.trim-td-text {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.wrap-text {
  white-space: pre-wrap;
}
.candidate-td h4 {
  font-size: 18px;
}

.btn-custom {
  color: #8ac5a4;
}
.btn-custom:hover {
  text-decoration: none;
  color: #7f9e8d;
}
i.material-icons {
  font-size: 10px;
}
i.material-icons:first-child {
  padding-right: 5px;
}

.signup-alt-link {
  float: right;
  color: black;
}
.signup-alt-link:hover {
  color: #2d2d2d;
  text-decoration: none;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #3f9b01;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

body { padding: 30px; }

@mixin can-toggle-branding(
  $can-toggle-off-color: #000dff,
  $can-toggle-on-color: #5fc054,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0,1,0.5,1)
) {

  input[type="checkbox"] {

    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:focus ~ label, &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
        &:after { color: darken($can-toggle-off-color, 10%); }
      }
    }
    &:hover ~label { color: darken($can-toggle-off-color, 5%); }
    &:checked {
      ~ label {
        &:hover { color: darken($can-toggle-on-color, 3%); }

        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after { color: darken($can-toggle-on-color, 5%); }
        }
      }

      &:focus, &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
            &:after { color: darken($can-toggle-on-color, 10%); }
          }
        }
      }
    }
  }

  label {

    .can-toggle__label-text { flex: 1; }

    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before { color: $can-toggle-inactive-text; }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color;
      }
    }

  }
}

@mixin can-toggle-appearance
(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
){
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type="checkbox"] {

    &:focus ~ label, &:hover ~ label {
      .can-toggle__switch {
        &:after { box-shadow: $can-toggle-shadow; }
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after { transform: translate3d($can-toggle-width - ($can-toggle-switch-width + $can-toggle-offset),0,0); }
        }
      }
      &:focus, &:hover {
        ~ label {
          .can-toggle__switch { &:after { box-shadow: $can-toggle-shadow; } }
        }
      }
    }
  }

  label {
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset; left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset*2);
        font-size: $can-toggle-switch-font-size;
      }

      &:hover {
        &:after { box-shadow: $can-toggle-shadow; }
      }
    }
  }
}



.can-toggle {
  position: relative;
  *, *:before, *:after { box-sizing: border-box; }
  //overflow: hidden;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0; left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch { opacity: 0.4; }
    }

    &:checked {
      ~ label {

        .can-toggle__switch {

          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }

      &:focus, &:hover {
        ~ label {
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__label-text {
      flex: 1;
      padding-left: 32px;
    }

    .can-toggle__switch {
      position: relative;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0,0,0);
      }

    }

  }

  // Default values for .can-toggle class
  @include can-toggle-branding;
  @include can-toggle-appearance;

  // Create toggles of different sizes by overriding the can-toggle-appearance mixin defaults
  &.can-toggle--size-small {
    @include can-toggle-appearance
    (
                    90px, // Toggle width
                    28px,  // Toggle height
                    2px,   // Toggle border radius
                    1px,   // Offset (distance btw switch and box)
                    13px,  // Label font size
                    10px,  // Switch font size
                    0 2px 2px rgba(black, 0.4) // Switch shadow on hover/focus
    );
  }
}